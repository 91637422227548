<template>
  <el-main v-if="info.id">
    <div class="order-info">
      <div class="title">
        订单信息
        <div v-if="info.store_id">
          <el-tag type="success">工厂店服务</el-tag>
          （{{ info.shop_info.store_name }}）
        </div>
        <el-tag v-else style="margin-left: 15px" type="primary" size="small">平台服务</el-tag>
      </div>
      <div class="order-content">
        <div class="order-title">
          <p>
            订单编号：
            <span>{{ info.pay_sn }}</span>
          </p>
          <p>
            提交时间：
            <span>{{ info.create_time }}</span>
          </p>
          <p>
            订单来源：
            <span>{{ info.from == 1 ? '微信' : info.from == 2 ? '支付宝' : '快应用' }}</span>
          </p>
        </div>
        <div class="order-process" v-if="progress_status1">
          <div class="item" style="background: #ddddfd">
            <div class="num" style="background: #409eff; color: #fff">1</div>
            <p class="handle-text" style="color: #409eff">提交时间</p>
            <p class="handle-time">{{ info.create_time }}</p>
          </div>
          <div class="line"></div>
          <div class="item" :style="{ background: info.cancel_time ? '#ddddfd' : '' }">
            <div class="num" :style="{ background: info.cancel_time ? '#409EFF' : '', color: info.cancel_time ? '#fff' : '' }">2</div>
            <p class="handle-text" :style="{ color: info.cancel_time ? '#409EFF' : '' }">已关闭</p>
            <p class="handle-time" v-if="info.cancel_time">{{ info.cancel_time }}</p>
          </div>
        </div>
        <div class="order-process" v-if="progress_status2">
          <div class="item" style="background: #ddddfd">
            <div class="num" style="background: #409eff; color: #fff">1</div>
            <p class="handle-text" style="color: #409eff">提交时间</p>
            <p class="handle-time">{{ info.create_time }}</p>
          </div>
          <div class="line"></div>
          <div class="item" :style="{ background: info.payment_time ? '#ddddfd' : '' }">
            <div class="num" :style="{ background: info.payment_time ? '#409EFF' : '', color: info.payment_time ? '#fff' : '' }">2</div>
            <p class="handle-text" :style="{ color: info.payment_time ? '#409EFF' : '' }">待进行</p>
            <p class="handle-time" v-if="info.payment_time">{{ info.payment_time }}</p>
          </div>
          <div class="line"></div>
          <div class="item" :style="{ background: info.cancel_time ? '#ddddfd' : '' }">
            <div class="num" :style="{ background: info.cancel_time ? '#409EFF' : '', color: info.cancel_time ? '#fff' : '' }">3</div>
            <p class="handle-text" :style="{ color: info.cancel_time ? '#409EFF' : '' }">已关闭</p>
            <p class="handle-time" v-if="info.cancel_time">{{ info.cancel_time }}</p>
          </div>
        </div>
        <div class="order-process" v-if="progress_status3">
          <div class="item" style="background: #ddddfd">
            <div class="num" style="background: #409eff; color: #fff">1</div>
            <p class="handle-text" style="color: #409eff">提交时间</p>
            <p class="handle-time">{{ info.create_time }}</p>
          </div>
          <div class="line"></div>
          <div class="item" :style="{ background: info.payment_time ? '#ddddfd' : '' }">
            <div class="num" :style="{ background: info.payment_time ? '#409EFF' : '', color: info.payment_time ? '#fff' : '' }">2</div>
            <p class="handle-text" :style="{ color: info.payment_time ? '#409EFF' : '' }">待进行</p>
            <p class="handle-time" v-if="info.payment_time">{{ info.payment_time }}</p>
          </div>
          <div class="line"></div>
          <div class="item" :style="{ background: info.delay_time ? '#ddddfd' : '' }">
            <div class="num" :style="{ background: info.delay_time ? '#409EFF' : '', color: info.delay_time ? '#fff' : '' }">3</div>
            <p class="handle-text" :style="{ color: info.delay_time ? '#409EFF' : '' }">进行中</p>
            <p class="handle-time" v-if="info.delay_time">{{ info.delay_time }}</p>
          </div>
          <div class="line"></div>
          <div class="item" :style="{ background: info.cancel_time ? '#ddddfd' : '' }">
            <div class="num" :style="{ background: info.cancel_time ? '#409EFF' : '', color: info.cancel_time ? '#fff' : '' }">4</div>
            <p class="handle-text" :style="{ color: info.cancel_time ? '#409EFF' : '' }">已关闭</p>
            <p class="handle-time" v-if="info.cancel_time">{{ info.cancel_time }}</p>
          </div>
        </div>
        <div class="order-process" v-if="progress_status4">
          <div class="item" style="background: #ddddfd">
            <div class="num" style="background: #409eff; color: #fff">1</div>
            <p class="handle-text" style="color: #409eff">提交时间</p>
            <p class="handle-time">{{ info.create_time }}</p>
          </div>
          <div class="line"></div>
          <div class="item" :style="{ background: info.payment_time ? '#ddddfd' : '' }">
            <div class="num" :style="{ background: info.payment_time ? '#409EFF' : '', color: info.payment_time ? '#fff' : '' }">2</div>
            <p class="handle-text" :style="{ color: info.payment_time ? '#409EFF' : '' }">待进行</p>
            <p class="handle-time" v-if="info.payment_time">{{ info.payment_time }}</p>
          </div>
          <div class="line"></div>
          <div class="item" :style="{ background: info.delay_time ? '#ddddfd' : '' }">
            <div class="num" :style="{ background: info.delay_time ? '#409EFF' : '', color: info.delay_time ? '#fff' : '' }">3</div>
            <p class="handle-text" :style="{ color: info.delay_time ? '#409EFF' : '' }">进行中</p>
            <p class="handle-time" v-if="info.delay_time">{{ info.delay_time }}</p>
          </div>
          <div class="line"></div>
          <div class="item" :style="{ background: info.finnshed_time ? '#ddddfd' : '' }">
            <div class="num" :style="{ background: info.finnshed_time ? '#409EFF' : '', color: info.finnshed_time ? '#fff' : '' }">4</div>
            <p class="handle-text" :style="{ color: info.finnshed_time ? '#409EFF' : '' }">师傅确认竣工</p>
            <p class="handle-time" v-if="info.finnshed_time">{{ info.finnshed_time }}</p>
          </div>
          <div class="line"></div>
          <div class="item" :style="{ background: info.cancel_time ? '#ddddfd' : '' }">
            <div class="num" :style="{ background: info.cancel_time ? '#409EFF' : '', color: info.cancel_time ? '#fff' : '' }">5</div>
            <p class="handle-text" :style="{ color: info.cancel_time ? '#409EFF' : '' }">已关闭</p>
            <p class="handle-time" v-if="info.cancel_time">{{ info.cancel_time }}</p>
          </div>
        </div>
        <div class="order-process" v-if="!progress_status1 && !progress_status2 && !progress_status3 && !progress_status4">
          <div class="item" style="background: #ddddfd">
            <div class="num" style="background: #409eff; color: #fff">1</div>
            <p class="handle-text" style="color: #409eff">提交时间</p>
            <p class="handle-time">{{ info.create_time }}</p>
          </div>
          <div class="line"></div>
          <div class="item" :style="{ background: info.payment_time ? '#ddddfd' : '' }">
            <div class="num" :style="{ background: info.payment_time ? '#409EFF' : '', color: info.payment_time ? '#fff' : '' }">2</div>
            <p class="handle-text" :style="{ color: info.payment_time ? '#409EFF' : '' }">待进行</p>
            <p class="handle-time" v-if="info.payment_time">{{ info.payment_time }}</p>
          </div>
          <div class="line"></div>
          <div class="item" :style="{ background: info.delay_time ? '#ddddfd' : '' }">
            <div class="num" :style="{ background: info.delay_time ? '#409EFF' : '', color: info.delay_time ? '#fff' : '' }">3</div>
            <p class="handle-text" :style="{ color: info.delay_time ? '#409EFF' : '' }">进行中</p>
            <p class="handle-time" v-if="info.delay_time">{{ info.delay_time }}</p>
          </div>
          <div class="line"></div>
          <div class="item" :style="{ background: info.finnshed_time ? '#ddddfd' : '' }">
            <div class="num" :style="{ background: info.finnshed_time ? '#409EFF' : '', color: info.finnshed_time ? '#fff' : '' }">4</div>
            <p class="handle-text" :style="{ color: info.finnshed_time ? '#409EFF' : '' }">师傅确认竣工</p>
            <p class="handle-time" v-if="info.finnshed_time">{{ info.finnshed_time }}</p>
          </div>
          <div class="line"></div>
          <div class="item" :style="{ background: info.acceptance_time ? '#ddddfd' : '' }">
            <div class="num" :style="{ background: info.acceptance_time ? '#409EFF' : '', color: info.acceptance_time ? '#fff' : '' }">5</div>
            <p class="handle-text" :style="{ color: info.acceptance_time ? '#409EFF' : '' }">用户确认验收</p>
            <p class="handle-time" v-if="info.acceptance_time">{{ info.acceptance_time }}</p>
          </div>
        </div>
        <div class="order-status">
          <div class="status">
            订单状态：
            {{
              info.state == 0
                ? '已关闭'
                : info.state == 10
                ? '待确认'
                : info.state == 20
                ? '待进行'
                : info.state == 30 && info.finnshed_time
                ? '师傅确认竣工'
                : info.state == 30
                ? '进行中'
                : '用户确认验收'
            }}
          </div>
          <!-- <div class="btn" v-if="info.state == 10">
            <el-button type="primary" @click="confirmReceipt">派单</el-button>
          </div> -->
        </div>
      </div>
    </div>
    <div class="buyers-info">
      <div class="title">买家信息</div>

      <div class="buyers-content">
        <p>付款人：{{ info.consignee || '--' }}</p>
        <p>付款人电话：{{ info.consignee_mobile || '--' }}</p>
      </div>
    </div>
    <div class="service-info">
      <div class="title">服务信息</div>
      <table v-if="info.goods_type != 1">
        <thead>
          <tr>
            <th>服务信息</th>
            <th>服务类别</th>
            <th v-for="item in info.extend_order_goods[0].sku_jsons" :key="item">{{ item.title }}</th>
            <th>施工条件</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="info">
              <el-image style="width: 50px; height: 50px" :src="info.extend_order_goods[0].service_picture"></el-image>
              <span>{{ info.extend_order_goods[0].service_title }}</span>
            </td>
            <td>{{ info.goods_type == 1 ? '快速需求' : info.goods_type == 2 ? '找安装' : info.goods_type == 3 ? '做广告' : '做设计' }}</td>
            <td v-for="item in info.extend_order_goods[0].sku_jsons" :key="item">{{ item.content }}</td>
            <td>{{ info.consignee_conditions_text || '--' }}</td>
          </tr>
        </tbody>
      </table>
      <table v-if="info.goods_type == 2 || info.goods_type == 3">
        <thead>
          <tr>
            <th>现场联系人</th>
            <th>现场联系人电话</th>
            <th>预约时间</th>
            <th>服务地址</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ info.extend_order_extend.reciver_infos.name }}</td>
            <td>{{ info.extend_order_extend.reciver_infos.phone }}</td>
            <td>{{ getDateformat(info.consignee_time) }}</td>
            <td>{{ info.extend_order_extend.reciver_infos.combine_detail.replace(/\s+/g, '') }}{{ info.extend_order_extend.reciver_infos.address }}</td>
          </tr>
        </tbody>
      </table>
      <div class="img">
        <span>现场图片/技术文件：</span>
        <el-image v-for="(item, index) in info.extend_order_extend.scene_picture" :key="index" :src="item" :preview-src-list="info.extend_order_extend.scene_picture"></el-image>
      </div>
      <div class="message">现场描述：{{ info.message }}</div>
      <div class="contract" v-if="info.state > 10 && info.contract">
        <span>相关合同：</span>
        <span v-if="!JSON.parse(info.contract).length">--</span>
        <el-image :src="item" :preview-src-list="JSON.parse(info.contract)" v-for="(item, index) in JSON.parse(info.contract)" :key="index"></el-image>
      </div>
    </div>
    <div class="meet-info" v-if="info.state == 10">
      <div class="title">派单/接单信息</div>
      <table>
        <thead>
          <tr>
            <th>服务类型</th>
            <th>接单师傅</th>
            <th>接单师傅电话</th>
            <th>报价价格</th>
            <th>派单情况</th>
            <th>状态</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in info.extend_order_follow" :key="index">
            <td>{{ item.cur_info.service_type == 1 ? '广告类' : item.cur_info.service_type == 2 ? '安装类' : '设计类' }}</td>
            <td>{{ item.cur_info.name }}</td>
            <td>{{ item.cur_info.mobile }}</td>
            <td>{{ item.prequotation }}</td>
            <td>{{ item.order_case == 0 ? '师傅抢单' : item.order_case == 1 ? '会员派单' : '平台派单' }}</td>
            <td>{{ item.order_status == 0 ? '待沟通' : item.order_status == 1 ? '待报价' : '待支付' }}</td>
          </tr>
          <tr v-if="!info.extend_order_follow.length">
            <td colspan="8">暂无内容</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="construction-info" v-if="info.state >= 20">
      <div class="title">施工信息</div>
      <table>
        <thead>
          <tr>
            <th>服务类型</th>
            <th>接单师傅（创建者）</th>
            <th>接单师傅电话</th>
            <th>支付金额</th>
            <th>抵达时间</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ info.receiving_cur_info.service_type == 1 ? '广告类' : info.receiving_cur_info.service_type == 2 ? '安装类' : '设计类' }}</td>
            <td>{{ info.receiving_cur_info.name }}</td>
            <td>{{ info.receiving_cur_info.mobile }}</td>
            <td>{{ info.amount }}</td>
            <td>{{ getDateformat(info.receiving_cur_info.create_time) }}</td>
          </tr>
        </tbody>
      </table>
      <div class="construction-img" v-if="info.state >= 30">
        <div class="title">施工照片</div>
        <div class="img-type">
          <div class="img">  
            <span>确认服务：</span>
            <div>
              <p>{{ info.extend_order_extend.complete_commencement }}</p>
              <el-image :src="item" :preview-src-list="info.extend_order_extend.commencement_pictures" v-for="(item, index) in info.extend_order_extend.commencement_pictures" :key="index"></el-image>
            </div>
          </div>
          <div class="img">
            <span>进行中：</span>
            <div>
              <p>{{ info.extend_order_extend.ongoing_commencement }}</p>
              <el-image :src="item" :preview-src-list="info.extend_order_extend.ongoing_pictures" v-for="(item, index) in info.extend_order_extend.ongoing_pictures" :key="index"></el-image>
            </div>
          </div>
          <div class="img" v-if="info.state == 50">
            <span>验收照片：</span>
            <div>
              <p>{{ info.extend_order_extend.confirm_commencement }}</p>
              <el-image :src="item" :preview-src-list="info.extend_order_extend.complete_pictures" v-for="(item, index) in info.extend_order_extend.complete_pictures" :key="index"></el-image>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-main>
</template>

<script>
import { getDateformat } from '@/util/getDate';
export default {
  data() {
    return {
      info: {},
      progress_status1: 0,
      progress_status2: 0,
      progress_status3: 0,
      progress_status4: 0,
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    getDateformat(val) {
      return getDateformat(val);
    },
    getInfo() {
      this.$axios
        .post(this.$api.samecity.service.orderInfo, {
          id: this.$route.query.id,
        })
        .then(res => {
          if (res.code == 0) {
            let info = res.result.info;
            let stateArr = [10];
            let consignee_conditions = info.extend_order_extend.consignee_conditions;
            let consignee_conditions_text = '';
            if (info.payment_time) stateArr.push(20);
            if (info.cancel_time) stateArr.push(0);
            if (info.delay_time) stateArr.push(30);
            if (info.finnshed_time) stateArr.push(40);
            if (info.acceptance_time) stateArr.push(50);
            if ([0, 10].sort().toString() == stateArr.sort().toString()) this.progress_status1 = 1;
            if ([0, 10, 20].sort().toString() == stateArr.sort().toString()) this.progress_status2 = 1;
            if ([0, 10, 20, 30].sort().toString() == stateArr.sort().toString()) this.progress_status3 = 1;
            if ([0, 10, 20, 30, 40].sort().toString() == stateArr.sort().toString()) this.progress_status4 = 1;
            if (consignee_conditions) {
              if (consignee_conditions.height == 1) {
                consignee_conditions_text += '施工高度大于三米、';
              }
              if (consignee_conditions.procedures == 1) {
                consignee_conditions_text += '要办理施工手续、';
              }
              if (consignee_conditions.time == 1) {
                consignee_conditions_text += '白天施工';
              } else {
                consignee_conditions_text += '晚上施工';
              }
              info.consignee_conditions_text = consignee_conditions_text;
            }
            this.info = info;
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  font-size: 14px;
  table {
    width: 100%;
    border: 1px solid #f8f9fa;
    margin-bottom: 20px;
    thead {
      background: #f8f9fa;
      tr th {
        padding: 15px;
        text-align: center;
      }
    }
    tbody {
      tr td {
        padding: 15px;
        text-align: center;
      }
    }
  }
  .title {
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
    margin-bottom: 20px;
  }
  .order-info {
    margin-bottom: 20px;
    .order-content {
      .order-title {
        display: flex;
        height: 47px;
        background: #f0f0f5;
        align-items: center;
        padding-left: 24px;
        p {
          font-size: 14px;
          margin-right: 40px;
          span {
            font-weight: 400;
            color: #17171a;
          }
        }
      }
    }
    .order-process {
      display: flex;
      justify-content: center;
      height: 190px;
      align-items: center;
      .line {
        height: 4px;
        width: 120px;
        background: #f0f0f5;
      }
      .item {
        background: #fff;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .num {
          width: 32px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          background: #c8c8cc;
          border-radius: 50%;
          color: #ffffff;
          font-size: 14px;
          font-weight: bold;
        }
        .handle-text {
          width: 100px;
          text-align: center;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: -25px;
          font-size: 14px;
          font-weight: bold;
          color: #969699;
        }
        .handle-time {
          width: 120px;
          position: absolute;
          left: 50%;
          text-align: center;
          transform: translateX(-50%);
          bottom: -25px;
          font-size: 12px;
          font-weight: 400;
          color: #969699;
        }
      }
    }
    .order-status {
      padding: 30px 0;
      background: #f0f0f5;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .status {
        font-size: 16px;
        font-weight: bold;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        color: #969699;
      }
      .btn {
        margin-top: 18px;
      }
    }
  }
  .buyers-info {
    margin-bottom: 20px;
    .buyers-content {
      display: flex;
      p {
        margin-right: 40px;
      }
    }
  }
  .service-info {
    margin-bottom: 20px;
    table {
      .info {
        display: flex;
        align-items: center;
        .el-image {
          margin-right: 15px;
        }
      }
    }
    .img,
    .message,
    .contract {
      padding-left: 100px;
    }
    .message {
      margin-top: 20px;
    }
    .img,
    .contract {
      display: flex;
      align-items: center;
      .el-image {
        width: 50px;
        height: 50px;
        margin-right: 15px;
      }
    }
    .contract {
      margin-top: 20px;
    }
  }
  .construction-img {
    display: flex;
    .img-type {
      margin-left: 30px;
      .img {
        margin-bottom: 20px;
        display: flex;
        span {
          width: 80px;
        }
        & > div {
          p {
            margin-bottom: 15px;
          }
          .el-image {
            width: 50px;
            height: 50px;
            margin-right: 15px;
          }
        }
      }
    }
  }
}
</style>